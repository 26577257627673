import { Row, Col, Skeleton } from "antd";
import { useEffect, useCallback } from "react";
import { api, cart } from "../../services/website.service";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createAlert } from "../../components/Notification";
import SliderPrevArrow from "../../components/SliderPrevArrow";
import SliderNextArrow from "../../components/SliderNextArrow";
import Product from "../../components/Product";
import { app_url } from "../../utilities/config";
//
import CitySelection from "../../components/CitySelection";
import { useDispatch } from "react-redux";
import { getCartItems } from "../../store/cartSlice";
import ProductMobile from "../../components/ProductMobile";
import { useMediaQuery } from "react-responsive";

function Home() {
  const slider = React.useRef(null);
  const slider2 = React.useRef(null);
  const slider3 = React.useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //
  const [latestProducts, setLatestProducts] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [dealProducts, setDealProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState([]);
  const [categories, setCategories] = useState([]);
  localStorage.setItem("pincode","624619");

  const isMobileDevice = !useMediaQuery({
    query: "(min-device-width: 550px)",
  });

  //

  const fetchData = useCallback(async () => {
    setLoading(true);
    // Latest products
    await api
      .fetch_home_products({ filter: "latest", pincode: "364001" })
      .then((res) => {
        setLatestProducts(res.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching latest products ->", err.message);
      });
    // Featured products
    await api
      .fetch_home_products({ filter: "featured", pincode: "364001" })
      .then((res) => {
        setFeaturedProducts(res.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching latest products ->", err.message);
      });
    // Deal products
    await api
      .fetch_home_products({ filter: "deals", pincode: "364001" })
      .then((res) => {
        setDealProducts(res.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching deal products ->", err.message);
      });
    // Banners
    await api
      .fetch_banners()
      .then((res) => {
        // console.log(res.data);
        setBanner(res.data.data);
        // setBanner([{}, {}]);
      })
      .catch((err) => {
        console.error("Error while fetching banners ->", err.message);
      });
    // Fetching categories
    await api
      .fetch_product_categories()
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching categories ->", err.message);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const bannerSettings = {
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    useCss: true,
  };

  const sliderSettings = {
    arrows: true,
    dots: false,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    useCss: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1043,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const addToCart = async (id) => {
    await cart
      .add({
        product_id: id,
        quantity: 1,
      })
      .then((res) => {
        console.log(res);
        if (res.success) {
          dispatch(getCartItems());
          createAlert("success", "Item successfully added to cart.");
        } else {
          if (res.code === 401) {
            navigate("/login");
            createAlert("warning", "You have to login to add items to cart.");
          } else {
            createAlert("error", res.message);
          }
        }
      });
  };

  const loadingElement = () => {
    return (
      <Row gutter={20}>
        <Col sm={8}>
          <div className="skeleton text-left border border-slate-100 p-4 mb-5">
            <Skeleton.Image active size="large" className="mb-4" />
            <Skeleton active />
            <Skeleton.Button active size="large" />
          </div>
        </Col>
        <Col sm={8}>
          <div className="skeleton text-left border border-slate-100 p-4 mb-5">
            <Skeleton.Image active size="large" className="mb-4" />
            <Skeleton active />
            <Skeleton.Button active size="large" />
          </div>
        </Col>
        <Col span={8}>
          <div className="skeleton text-left border border-slate-100 p-4 mb-5">
            <Skeleton.Image active size="large" className="mb-4" />
            <Skeleton active />
            <Skeleton.Button active size="large" />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="mt-5 mb-10">
        <div
          className={`"slider-wrapper w-full rounded overflow-hidden ${
            banner.length && "border"
          } border-slate-300`}
        >
          <Slider {...bannerSettings} className="w-full">
            {banner &&
              banner.map((e) => {
                if (e.home_banner) {
                  return (
                    <div
                      onClick={() =>
                        e.product
                          ? navigate(`/products/view/?id=${e.product}`)
                          : null
                      }
                      className="w-full shadow-sm"
                      key={e.id}
                    >
                      <img
                        className="w-full object-cover"
                        style={{ maxHeight: 500 }}
                        src={app_url + e.image}
                        alt=""
                      />
                    </div>
                  );
                } else {
                  return null;
                }
              })}
          </Slider>
        </div>
      </div>

      {/*  */}

      <div className="mb-5">
        <CitySelection />
      </div>
      {/* Start -- Categories */}
      {/* <h1 className="text-2xl mb-5 mt-15 font-semibold">Categories</h1>
      <Row className="mb-10" gutter={20}>
        {categories &&
          categories.map((e) => {
            return (
              <Col sm={12} md={8} lg={6} key={e.title}>
                <div className="bg-sky-50 p-4 rounded-md flex w-64 sm:w-full  items-center first-letter:hover:bg-sky-100 transition cursor-pointer mb-4">
                  <img
                    src="https://img.icons8.com/external-smashingstocks-detailed-outline-smashing-stocks/50/000000/external-groceries-shopping-and-commerce-smashingstocks-detailed-outline-smashing-stocks-2.png"
                    alt="appliances"
                    className="self-center"
                  />
                  <h6 className="text-sky-900 mt-2 mb-0 text-center">
                    {" "}
                    {e.title}{" "}
                  </h6>
                </div>
              </Col>
            );
          })}
      </Row> */}
      {/* Ends -- Categories */}

      {latestProducts.length > 0 ? (
        <div className="latest-products">
          <div className="p-4 mb-5 rounded-md">
            <h1 className="text-sky-900 text-2xl sm:text-3xl font-extrabold mb-0  whitespace-nowrap  ">
              <img
                src="https://img.icons8.com/external-line-adri-ansyah/86/1e3a8a/external-new-new-year-line-adri-ansyah-4.png"
                className="mr-4"
                width={36}
                alt="new"
              />
              Latest Products
            </h1>
          </div>

          {loading ? loadingElement() : ""}

          <div className="slider-wrapper relative w-full">
            {latestProducts.length > 2 ? (
              <div>
                <Slider ref={slider} {...sliderSettings} className="w-full">
                  {latestProducts.map((e) => {
                    if (isMobileDevice)
                      return (
                        <div key={e.id}>
                          <ProductMobile
                            variant={"small"}
                            assured={e.assured}
                            stock_qty={e.stock_qty}
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </div>
                      );
                    else
                      return (
                        <div key={e.id}>
                          <Product
                            assured={e.assured}
                            stock_qty={e.stock_qty}
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          ></Product>
                        </div>
                      );
                  })}
                </Slider>
                <div className="absolute top-1/2 left-0 transform -translate-x-2 -translate-y-8">
                  <SliderPrevArrow
                    onClick={() => slider?.current?.slickPrev()}
                  />
                </div>
                <div className="absolute top-1/2 right-0 transform -translate-y-8 translate-x-2">
                  <SliderNextArrow
                    onClick={() => slider?.current?.slickNext()}
                  />
                </div>
              </div>
            ) : (
              <div>
                <Row>
                  {latestProducts.map((e) => {
                    if (isMobileDevice)
                      return (
                        <Col span={12} key={e.id}>
                          <Product
                            variant={"small"}
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                    else
                      return (
                        <Col lg={8} md={12} sm={24} key={e.id}>
                          <Product
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                  })}
                </Row>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* banner - start */}
      <div
        className="banner border-1 border-slate-300 overflow-hidden rounded mb-5"
        style={{ height: 250 }}
      >
        <Slider {...bannerSettings} className="w-full h-full">
          {banner &&
            banner.map((e) => {
              if (e.product_banner) {
                return (
                  <div
                    className="w-full h-full "
                    onClick={() =>
                      e.product
                        ? navigate(`/products/view/?id=${e.product}`)
                        : null
                    }
                    key={e.id}
                  >
                    <img
                      className="w-full object-cover"
                      style={{ height: "100%", width: "100%", minHeight: 250 }}
                      src={app_url + e.image}
                      alt=""
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
        </Slider>
      </div>
      {/* banner - ends */}

      {featuredProducts.length > 0 ? (
        <div className="featured-products">
          <div className="p-4 mb-5 rounded-md">
            <h1 className="text-yellow-700 text-2xl sm:text-3xl font-extrabold mb-0 whitespace-nowrap ">
              <img
                src="https://img.icons8.com/ios/36/78350f/sparkling.png"
                className="mr-4"
                alt="new"
              />
              Featured Products
            </h1>
          </div>
          {loading ? loadingElement() : ""}
          <div className="slider-wrapper relative w-full">
            {featuredProducts?.length > 2 ? (
              <div>
                <Slider ref={slider2} {...sliderSettings} className="w-full">
                  {featuredProducts.map((e) => {
                    if (isMobileDevice)
                      return (
                        <div key={e.id}>
                          <ProductMobile
                            variant={"small"}
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </div>
                      );
                    else
                      return (
                        <div key={e.id}>
                          <Product
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </div>
                      );
                  })}
                </Slider>
                <div className="absolute top-1/2 left-0 transform -translate-x-2 -translate-y-8">
                  <SliderPrevArrow
                    onClick={() => slider2?.current?.slickPrev()}
                  />
                </div>
                <div className="absolute top-1/2 right-0 transform -translate-y-8 translate-x-2">
                  <SliderNextArrow
                    onClick={() => slider2?.current?.slickNext()}
                  />
                </div>
              </div>
            ) : (
              <div>
                <Row>
                  {featuredProducts.map((e) => {
                    if (isMobileDevice)
                      return (
                        <Col span={12} key={e.id}>
                          <ProductMobile
                            id={e.id}
                            variant={"small"}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                    else
                      return (
                        <Col lg={8} md={12} xs={24} sm={24} key={e.id}>
                          <Product
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          ></Product>
                        </Col>
                      );
                  })}
                </Row>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* Featured products */}

      {/* Deal Products*/}
      {dealProducts.length > 1 ? (
        <div className="deal-products">
          <div className=" p-4 mb-5 rounded-md">
            <h1 className="text-red-900 text-2xl sm:text-3xl font-extrabold mb-0 whitespace-nowrap  ">
              <img
                src="https://img.icons8.com/office/86/000000/discount.png"
                className="mr-4"
                alt="new"
                width={36}
              />
              Hot Deals
            </h1>
          </div>
          {loading ? loadingElement() : ""}
          <div className="slider-wrapper relative w-full">
            {dealProducts?.length > 2 ? (
              <div>
                <Slider ref={slider3} {...sliderSettings} className="w-full">
                  {dealProducts.map((e) => {
                    if (isMobileDevice)
                      return (
                        <div key={e.id}>
                          <ProductMobile
                            variant={"small"}
                            assured={e.assured}
                            stock_qty={e.stock_qty}
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </div>
                      );
                    else
                      return (
                        <div key={e.id}>
                          <Product
                            assured={e.assured}
                            stock_qty={e.stock_qty}
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          ></Product>
                        </div>
                      );
                  })}
                </Slider>
                <div className="absolute top-1/2 left-0 transform -translate-x-2 -translate-y-8">
                  <SliderPrevArrow
                    onClick={() => slider3?.current?.slickPrev()}
                  />
                </div>
                <div className="absolute top-1/2 right-0 transform -translate-y-8 translate-x-2">
                  <SliderNextArrow
                    onClick={() => slider3?.current?.slickNext()}
                  />
                </div>
              </div>
            ) : (
              <div>
                <Row>
                  {dealProducts.map((e) => {
                    if (isMobileDevice)
                      return (
                        <Col lg={8} md={12} sm={24} xs={24} key={e.id}>
                          <ProductMobile
                            id={e.id}
                            variant={"small"}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                    else
                      return (
                        <Col lg={8} md={12} sm={24} xs={24} key={e.id}>
                          <Product
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={+e.review.toFixed(1)}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? app_url + e.thumbnail
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          ></Product>
                        </Col>
                      );
                  })}
                </Row>
              </div>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      {/* banner - start */}
      <div
        className="banner border-2 border-slate-300 overflow-hidden rounded mb-5"
        style={{ height: 250 }}
      >
        <Slider {...bannerSettings} className="w-full h-full">
          {banner &&
            banner.map((e) => {
              if (e.bottom_banner) {
                return (
                  <div
                    onClick={() =>
                      e.product
                        ? navigate(`/products/view/?id=${e.product}`)
                        : null
                    }
                    className="w-full h-full"
                    key={e.id}
                  >
                    <img
                      className="w-full object-cover"
                      style={{ height: "100%", width: "100%", minHeight: 250 }}
                      src={app_url + e.image}
                      alt=""
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
        </Slider>
      </div>
      {/* banner - ends */}
    </>
  );
}

export default Home;
