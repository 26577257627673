import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Input, Modal, Spin } from "antd";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { api } from "../services/website.service";
import { createAlert } from "./Notification";

function CitySelection(props) {
  const [cities, setCities] = useState([]);
  const [displayCities, setDisplayCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState({});
  const [isPincodeAvailable, setIsPincodeAvailable] = useState(true);
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);

  // areas
  const [areas, setAreas] = useState({});
  const [displayArea, setDisplayAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState({});

  //
  const [modalTitle, setModalTitle] = useState("City");

  //
  const [showAreas, setShowAreas] = useState(false);

  const get_cities = async () => {
    setLoading(true);
    await api.fetch_cities().then((res) => {
      setLoading(false);

      console.log(res.data);
      setCities(res.data.data);
      setDisplayCities(res.data.data);
    });
  };

  const arraySearch = (array, keyword, type) => {
    const searchTerm = keyword.toLowerCase();
    if (type === "city") {
      return array.filter((value) => {
        return value.name.toLowerCase().match(new RegExp(searchTerm, "g"));
      });
    }
    if (type === "area") {
      return array.filter((value) => {
        return value.city.toLowerCase().match(new RegExp(searchTerm, "g"));
      });
    }
  };

  const handleOnChange = async (e) => {
    let value = e.target.value;

    if (showAreas) {
      if (value.length > 2) {
        let search = await arraySearch(areas, value, "area");
        setDisplayAreas(search);
        setCount(search.length);
      } else {
        setDisplayAreas(areas);
        setCount(cities.length);
      }
    } else {
      if (value.length > 2) {
        let search = await arraySearch(cities, value, "city");
        setDisplayCities(search);
        setCount(search.length);
      } else {
        setDisplayCities(cities);
        setCount(cities.length);
      }
    }
  };

  const saveCity = (cityid) => {
    let find = cities.find((e) => e.id === cityid);
    console.log(find.city);
    setAreas(find.city);
    setDisplayAreas(find.city);
    setShowAreas(true);
    setModalTitle("Area");
  };

  const saveArea = (area) => {
    // let find = cities.find((e) => e.id === cityid);
    // console.log(find.city);
    // setAreas(find.city);
    // setDisplayAreas(find.city);
    // setShowAreas(true);
    // setModalTitle("Area");
    console.log(area);
    localStorage.setItem("pincode", area.pincode);
    closeModal();
    window.location.reload();
  };

  const checkCity = () => {
    let pincode = localStorage.getItem("pincode");
    // if (pincode) {
    //   setIsPincodeAvailable(true);
    // } else {
    //   setIsPincodeAvailable(false);
    // }
    return Boolean(pincode);
  };

  const closeModal = () => {
    const cityCheck = checkCity();
    setIsPincodeAvailable(cityCheck);
  };

  useEffect(() => {
    setIsPincodeAvailable(checkCity());
    get_cities();
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="city-selection max-w-lg">
      <Modal
        style={{
          width: "100%",
        }}
        footer={false}
        onCancel={closeModal}
        closable={false}
        // title="Select city"
        visible={!isPincodeAvailable || props.open}
      >
        {/* <CenteredDiv> */}
        <div className="">
          <div className="flex items-center">
            <img
              src="https://img.icons8.com/material-sharp/48/000000/city-buildings.png"
              width={30}
              alt=""
              className="mr-3"
            />
            <div>
              <p className="mb-0">Welcome to IGETIN. </p>
              <h5 className="text-slate-900 font-semibold text-lg mb-0">
                Please select {modalTitle}.
              </h5>
            </div>
          </div>

          {/* </CenteredDiv> */}
          {/* <CenteredDiv> */}

          {/* </CenteredDiv> */}

          {loading ? (
            <div className="flex w-100 h-100 justify-center items-center">
              <Spin indicator={antIcon} />
            </div>
          ) : (
            <div>
              {!showAreas ? (
                <div>
                  <div className="searchbar my-5">
                    <Input
                      placeholder="Type to search.."
                      onChange={handleOnChange}
                    ></Input>
                  </div>
                  <div>
                    <ul className="w-full rounded-lg mt-2 mb-3 text-slate-800 flex">
                      {displayCities.map((e) => {
                        return (
                          <li
                            className="mb-1 mr-3"
                            onClick={() => {
                              saveCity(e.id);
                            }}
                          >
                            <span
                              href="#"
                              alt="1"
                              className="w-fill px-6 py-3 border border-slate-300 hover:bg-slate-300 rounded-sm flex items-center transition-all cursor-pointer hover:shadow-sm"
                            >
                              <span className="truncate">{e.name}</span>
                            </span>
                          </li>
                        );
                      })}

                      {displayCities.length <= 0 ? (
                        <Alert type="warning" message="No data available." />
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="searchbar my-5">
                    <Input
                      placeholder="Type to search.."
                      onChange={handleOnChange}
                    ></Input>
                  </div>
                  <div>
                    <ul className="w-full rounded-lg mt-2 mb-3 text-slate-800 flex flex-wrap sm:flex-nowrap">
                      {displayArea.map((e) => {
                        return (
                          <li
                            className="mb-1 mr-3"
                            onClick={() => {
                              saveArea(e);
                            }}
                          >
                            <span
                              href="#"
                              alt="1"
                              className="w-fill px-6 py-3 border border-slate-300 hover:bg-slate-300 rounded-sm flex items-center transition-all cursor-pointer hover:shadow-sm mb-2"
                            >
                              <span className="truncate">{e.city}</span>
                            </span>
                          </li>
                        );
                      })}

                      {displayCities.length <= 0 ? (
                        <Alert type="warning" message="No data available." />
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

const CenteredDiv = ({ children }) => {
  return <div className="flex justify-center align-middle">{children}</div>;
};

export default CitySelection;
