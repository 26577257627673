import React from "react";

const Privacy = () => {
  return (
    <>
      <div
        style={{
          padding: "20px",
          paddingRight: "40px",
          paddingLeft: "40px",
        }}
      >
        <div>
          <h1
            style={{
              textAlign: "center",
              fontSize: "26px",
              fontWeight: "600",
            }}
          >
            PRIVACY STATEMENT
          </h1>
          <p
            style={{
              textAlign: "center",
            }}
          >
            ----
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            When you purchase something from our store, as part of the buying
            and selling process, we collect the personal information you give us
            such as your name, address and email address.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            When you browse our store, we also automatically receive your
            computer’s internet protocol (IP) address in order to provide us
            with information that helps us learn about your browser and
            operating system.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            Email marketing (if applicable): With your permission, we may send
            you emails about our store, new products and other updates.
          </p>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 2 - CONSENT
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            How do you get my consent?
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            When you provide us with personal information to complete a
            transaction, verify your credit card, place an order, arrange for a
            delivery or return a purchase, we imply that you consent to our
            collecting it and using it for that specific reason only.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            If we ask for your personal information for a secondary reason, like
            marketing, we will either ask you directly for your expressed
            consent, or provide you with an opportunity to say no.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            How do I withdraw my consent?
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            If after you opt-in, you change your mind, you may withdraw your
            consent for us to contact you, for the continued collection, use or
            disclosure of your information, at anytime, by contacting us at{" "}
            <strong>info@igetin.in</strong>or mailing us at:{" "}
            <strong>
              Igetin, D.No:124-G-8, Naganampatti, Opp sai baba kovil,
              Oddanchatram, Dindigul Dist, Tamil Nadu – 624619.
            </strong>
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 3 - DISCLOSURE
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            We may disclose your personal information if we are required by law
            to do so or if you violate our Terms of Service.
          </p>
          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 4 - PAYMENT
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            We use Razorpay for processing payments. We/Razorpay do not store
            your card data on their servers. The data is encrypted through the
            Payment Card Industry Data Security Standard (PCI-DSS) when
            processing payment. Your purchase transaction data is only used as
            long as is necessary to complete your purchase transaction. After
            that is complete, your purchase transaction information is not
            saved.
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            Our payment gateway adheres to the standards set by PCI-DSS as
            managed by the PCI Security Standards Council, which is a joint
            effort of brands like Visa, MasterCard, American Express and
            Discover.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            PCI-DSS requirements help ensure the secure handling of credit card
            information by our store and its service providers.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            For more insight, you may also want to read terms and conditions of
            razorpay on
            <a href="https://razorpay.com">https://razorpay.com</a>
          </p>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 5 - THIRD-PARTY SERVICES
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            In general, the third-party providers used by us will only collect,
            use and disclose your information to the extent necessary to allow
            them to perform the services they provide to us.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            However, certain third-party service providers, such as payment
            gateways and other payment transaction processors, have their own
            privacy policies in respect to the information we are required to
            provide to them for your purchase-related transactions.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            For these providers, we recommend that you read their privacy
            policies so you can understand the manner in which your personal
            information will be handled by these providers.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            In particular, remember that certain providers may be located in or
            have facilities that are located a different jurisdiction than
            either you or us. So if you elect to proceed with a transaction that
            involves the services of a third-party service provider, then your
            information may become subject to the laws of the jurisdiction(s) in
            which that service provider or its facilities are located.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            Once you leave our store’s website or are redirected to a
            third-party website or application, you are no longer governed by
            this Privacy Policy or our website’s Terms of Service.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontWeight: "500",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            Links
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            When you click on links on our store, they may direct you away from
            our site. We are not responsible for the privacy practices of other
            sites and encourage you to read their privacy statements.
          </p>
          <p
            fw={600}
            mt={20}
            mb={10}
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "10px",
              marginTop: "20px",
              fontWeight: "600",
            }}
          >
            SECTION 6 - SECURITY
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            To protect your personal information, we take reasonable precautions
            and follow industry best practices to make sure it is not
            inappropriately lost, misused, accessed, disclosed, altered or
            destroyed.
          </p>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 7 - COOKIES
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            We use cookies to maintain session of your user. It is not used to
            personally identify you on other websites.
          </p>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 8 - AGE OF CONSENT
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            By using this site, you represent that you are at least the age of
            majority in your state or province of residence, or that you are the
            age of majority in your state or province of residence and you have
            given us your consent to allow any of your minor dependents to use
            this site.
          </p>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            SECTION 9 - CHANGES TO THIS PRIVACY POLICY
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            We reserve the right to modify this privacy policy at any time, so
            please review it frequently. Changes and clarifications will take
            effect immediately upon their posting on the website. If we make
            material changes to this policy, we will notify you here that it has
            been updated, so that you are aware of what information we collect,
            how we use it, and under what circumstances, if any, we use and/or
            disclose it.
          </p>
          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            If our store is acquired or merged with another company, your
            information may be transferred to the new owners so that we may
            continue to sell products to you.
          </p>

          <p
            style={{
              fontSize: "16px",
              fontWeight: "600",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            QUESTIONS AND CONTACT INFORMATION
          </p>

          <p
            style={{
              textAlign: "justify",
              fontSize: "16px",
              marginBottom: "5px",
            }}
          >
            If you would like to: access, correct, amend or delete any personal
            information we have about you, register a complaint, or simply want
            more information contact our Privacy Compliance Officer at :{" "}
            <strong>info@igetin.in</strong> or by mail at :{" "}
            <strong>
              Igetin, D.No:124-G-8, Naganampatti, Opp sai baba kovil,
              Oddanchatram, Dindigul Dist, Tamil Nadu – 624619.
            </strong>
          </p>

          <p>----</p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
