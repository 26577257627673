import httpClient from "./config";
import axios from "axios";

const pincode = localStorage.getItem("pincode");

export const offers = {
  get: async () => await httpClient.get(`/product/offer/`),
  create: async (payload) => {
    return httpClient
      .post(`/product/offer/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  changeStatus: async (id, status) => {
    return httpClient
      .put(`/master/branch/?id=${id}`, { status: status })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const cart = {
  add: async (payload) => {
    return httpClient
      .post(`/cart/?add_item=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  checkCoupon: async (payload) => {
    return httpClient
      .post(`/cart/?apply_coupen=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  // get: async () => {
  //   return httpClient
  //     .get(`/cart/`)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       return err;
  //     });
  // },

  get: async () => {
    return new Promise((resolve, reject) =>
      setTimeout(
        () =>
          resolve(
            httpClient
              .get(`/cart/`)
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err;
              })
          ),
        300
      )
    );
  },
  editItem: async (payload) => {
    return httpClient
      .post(`/cart/?edit_item=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  removeItem: async (payload) => {
    return httpClient
      .post(`/cart/?remove_item=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const account = {
  get_address_details: async (payload) => {
    return axios
      .get(`https://api.postalpincode.in/pincode/${payload}`)
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
  },
};

export const address = {
  get: async () => {
    return httpClient
      .get(`/address/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  add: async (payload) => {
    return httpClient
      .post(`/address/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  update: async (payload) => {
    return httpClient
      .put(`/address/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  delete: async (payload) => {
    return httpClient
      .delete(`/address/?id=${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

// Payment

export const payment = {
  create_order: async (payload) => {
    return httpClient
      .post(`/get_order_id/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  verify: async (payload) => {
    return httpClient
      .post(`/get_verify_payment/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

// Wallet`
export const wallet = {
  get: async () => {
    return httpClient
      .get(`/master/wallet/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

// Orders

export const orders = {
  get: async () => {
    return httpClient
      .get(`/order/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  create: async (payload) => {
    return httpClient
      .post(`/order/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  cancle: async (id) => {
    return httpClient
      .put(`/order/`, { status: "cancelled", order_id: id })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const user = {
  // get: async () => await httpClient.get(`/product/offer/`),
  changePassword: async (payload) => {
    return httpClient
      .put(`/user/?change_password=true`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },

  edit_user: async (payload) => {
    return httpClient
      .put(`/user/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const review = {
  // get: async () => await httpClient.get(`/product/offer/`),
  add: async (payload) => {
    return httpClient
      .post(`/review/`, payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  get: async () => {
    return httpClient
      .get(`/review/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

export const regInBackend = async (e) => {
  return httpClient
    .post("/pushtoken/", {
      device_token: e,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const subscribePush = async () => {
  return axios
    .post("https://onesignal.com/api/v1/players", {
      app_id: "bc4248ab-b38d-41c9-8536-13622fa6d251",
      device_type: 5,
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const home = {
  getProducts: async (
    page_number,
    page_size,
    search,
    order,
    category = '',
    sub_category = null
  ) => {
    return httpClient
      .get(
        `/product/?pincode=${pincode}&page_size=${page_size}&page_number=${page_number}&search=${search}&order_by=${
          order ? order : "price"
        }&category_id=${category}&${
          sub_category ? "sub_category_id=" + sub_category : ""
        }`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getFilteredProducts: async (
    page_number,
    page_size,
    search,
    order,
    payload
  ) => {
    console.log(payload);
    return httpClient
      .get(
        `https://api.igetin.in/api/product/?pincode=${pincode}&page_size=${page_size}&page_number=${page_number}&search=${search}&order_by=${
          order ? order : "price"
        }&sub_category=True`,
        // "https://backend.dzone22.com/api/product/?pincode=624001&search=product&page_number=1&page_size=6&sub_category=true",
        { data: { sub_category_id: 2 } }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getCategorizedProducts: async (
    page_number,

    page_size,
    search,
    order,
    category,
    sub_category
  ) => {
    console.log(sub_category);
    return httpClient
      .get(
        `/product/?pincode=${pincode}&page_size=${page_size}&page_number=${page_number}&search=${search}&order_by=${
          order ? order : "price"
        }&category_id=${category}&${
          sub_category ? "sub_category_id=" + sub_category : ""
        }`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  getSingleProduct: async (payload) => {
    return httpClient
      .get(`/product/?product_id=${payload}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  get_products: async (type) => {
    return httpClient
      .get(`/product/?pincode=${pincode}&filter_type=${type}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  get_banner: async () => {
    return httpClient
      .get(`/product/banner/`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
};

// Refactoring the code ----

export const api = {
  fetch_home_products: (payload) => {
    return httpClient.get(
      `/product/?pincode=${pincode}&filter_type=${payload.filter}`
    );
  },
  fetch_banners: () => {
    return httpClient.get(`/banner/`);
  },
  fetch_product_categories: () => {
    return httpClient.get(`category/`);
  },
  fetch_cities: () => {
    return httpClient.get(`city/`);
  },
};
