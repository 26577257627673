import {
  FilterFilled,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Input,
  Modal,
  Radio,
  Row,
  Slider,
  Spin,
} from "antd";
import { check } from "prettier";
import { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { createAlert } from "../../components/Notification";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import Product from "../../components/Product";
import ProductMobile from "../../components/ProductMobile";
import { api, cart, home } from "../../services/website.service";
import { getCartItems } from "../../store/cartSlice";
import { app_url } from "../../utilities/config";

function Products() {
  const navigate = useNavigate();
  const isMobileDevice = !useMediaQuery({
    query: "(min-device-width: 550px)",
  });
  // States
  const [products, setProducts] = useState();
  const [order, setOrder] = useState("price");
  const [sort, setSort] = useState("a");
  const [displayProducts, setDisplayProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState();
  const [checkedSubCategories, setCheckedSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const [subCategoryId, setSubCategoryId] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFilterChecked, setIsFilterChecked] = useState(false);
  const dispatch = useDispatch();
  //
  const [selectedCategory, setselectedCategory] = useState(null);

  const pageSize = 6;

  const { Panel } = Collapse;

  // End - States

  const getData = useCallback(async () => {
    setLoading(true);
    console.log(order);
    await home.getProducts(page, pageSize, search, order).then((res) => {
      console.log(res);
      setProducts(res.data);
      setDisplayProducts(res.data);
    });
    // Fetching categories
    await api
      .fetch_product_categories()
      .then((res) => {
        setCategories(res.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching categories ->", err.message);
      });
    setLoading(false);
  }, [order]);

  const getSearchResults = useCallback(async () => {
    setLoading(true);
    setPage(1);
    await home.getProducts(1, pageSize, search).then((res) => {
      console.log(res.data);
      setProducts(res.data);
      setDisplayProducts(res.data);
    });
    setLoading(false);
  }, [search]);

  useEffect(() => {
    if (checkedSubCategories.length === 0) {
      setIsFilterChecked(false);
      getData();
    } else {
      setIsFilterChecked(true);
      filter_by_category();
    }
  }, [checkedSubCategories]);

  useEffect(() => {
    getData();
  }, [getData]);

  const filter_by_category = async () => {
    console.log(checkedSubCategories);
    await home
      .getCategorizedProducts(
        page,
        pageSize,
        search,
        order,
        checkedSubCategories[checkedSubCategories.length - 1].category,
        checkedSubCategories[checkedSubCategories.length - 1].subCategory
      )
      .then((res) => {
        console.log(res);
        setProducts(res.data);
        setDisplayProducts(res.data);
      });
  };

  const handleCategoryChange = (e) => {
    console.log(e);
  };

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const addToCart = async (id) => {
    await cart
      .add({
        product_id: id,
        quantity: 1,
      })
      .then((res) => {
        console.log(res);

        if (res.success) {
          dispatch(getCartItems());
          createAlert("success", "Item successfully added to cart.");
        } else {
          if (res.code === 401) {
            navigate("/login");
            createAlert("warning", "You have to login to add items to cart.");
          } else {
            createAlert("error", res.message);
          }
        }
      });
  };

  // Filter

  const filterProducts = (params) => {
    let filtered;
    if (params.cod) {
      filtered = products.filter((e) => {
        if (e.cod === params.cod) {
          return e;
        }
      });
      setDisplayProducts(filtered);
    } else {
      setDisplayProducts(products);
    }
    console.log(filtered);
  };

  // Fetch Data

  const fetchData = async () => {
    setLoading(true);
    if (isFilterChecked) filter_by_category();
    else
      await home.getProducts(page, pageSize, search).then((res) => {
        console.log(res);
        setProducts(res.data);
        if (res.success) {
          let oldProducts = displayProducts;
          let newProducts = [...oldProducts, ...res.data];
          setDisplayProducts(newProducts);
          setPage(page + 1);
        } else {
          setHasMore(false);
        }
      });
    setLoading(false);
  };

  // Range slider
  const marks = {
    0: "0",
    50: "50K",
  };
  const formatter = (value) => `${value * 1000}`;
  const closeModal = () => setIsModalVisible(false);

  return (
    <>
      <Modal
        title="Filters"
        footer={false}
        visible={isModalVisible}
        onCancel={closeModal}
      >
        {Filter()}
      </Modal>
      <h1 className="text-3xl mb-3 mt-3 font-semibold">All Products</h1>
      <PageBreadcrumb
        items={[
          { link: "/", title: "Home" },
          { link: "/products", title: "Products" },
        ]}
      />

      <Row className="mt-5" gutter={30}>
        {/* Filters */}
        <Col xs={0} lg={6}>
          <h5 className="font-semibold text-lg mb-4"> Filters </h5>

          {Filter()}
        </Col>
        <Col lg={18} xs={24} sm={24}>
          {/* Sort */}
          <div className="sort-area">
            <div className="flex sorters border-b justify-between sm:justify-start border-solid border-slate-200">
              <h5 className="font-semibold text-md mb-0 mr-4"> Sort By </h5>
              <div className="sorts flex">
                <span
                  className={`cursor-pointer mr-4 pb-1 ${
                    sort === "a"
                      ? "text-blue-400 border-b truncate border-solid border-blue-400"
                      : "text-slate-400 truncate"
                  }`}
                  onClick={() => {
                    setPage(1);
                    setOrder("price");
                    setSort("a");
                  }}
                >
                  Price Low to High
                </span>
                <span
                  className={`cursor-pointer mr-4 pb-1 ${
                    sort === "b"
                      ? "text-blue-400 border-b truncate border-solid border-blue-400"
                      : "text-slate-400 truncate"
                  }`}
                  onClick={() => {
                    setPage(1);
                    setOrder("-price");
                    setSort("b");
                  }}
                >
                  Price High to Low
                </span>
              </div>
            </div>
          </div>
          {/* Ends - Sort */}
          {/* Search */}

          {/* Ends - Search */}
          {/* -------------- */}
          {/* Products */}
          <div className="mt-8 flex">
            <Input
              className="custom-search"
              placeholder="Seach amoung hundereds of products...."
              prefix={<SearchOutlined />}
              onChange={(e) => {
                if (e.target.value.length > 0) {
                  setSearch(e.target.value);
                  getSearchResults();
                } else {
                  setSearch("");
                  setPage(1);
                  setHasMore(true);
                  getData();
                }
              }}
            />
            <div className=" lg:hidden">
              <Button
                className="ml-3 mt-1"
                shape="circle"
                onClick={() => setIsModalVisible(true)}
                icon={<FilterFilled />}
              />
            </div>
          </div>

          {/* Infinite scroll */}
          {displayProducts ? (
            <InfiniteScroll
              dataLength={displayProducts.length} //This is important field to render the next data
              next={fetchData}
              hasMore={hasMore}
              loader={
                <Spin
                  indicator={antIcon}
                  spinning={loading}
                  tip="Loading Products..."
                ></Spin>
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>You have seen all the products!</b>
                </p>
              }
            >
              <Row gutter={isMobileDevice ? 10 : 20} className="mt-10">
                {displayProducts &&
                  displayProducts.map((e, index) => {
                    if (isMobileDevice)
                      return (
                        <Col sm={12} xs={12} md={12} xxl={8} key={index}>
                          <ProductMobile
                            assured={e.assured}
                            variant="small"
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={e.review}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? `${app_url}${e.thumbnail}`
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                    else
                      return (
                        <Col sm={24} xs={24} md={12} xxl={8} key={index}>
                          <Product
                            assured={e.assured}
                            variant="small"
                            id={e.id}
                            title={e.title}
                            shop={e.shop_name}
                            mrp={e.mrp}
                            star={e.review}
                            price={e.price}
                            description={e.description}
                            category={e.category}
                            cartClick={() => {
                              addToCart(e.id);
                            }}
                            image={`${
                              e.thumbnail
                                ? `${app_url}${e.thumbnail}`
                                : "https://img.freepik.com/free-psd/packaging-box-mock-up_23-2148698793.jpg?w=2000"
                            }`}
                          />
                        </Col>
                      );
                  })}
              </Row>
            </InfiniteScroll>
          ) : (
            ""
          )}

          {/* Ends - infinite scroll  */}

          {/* Ends -- Products */}
        </Col>
      </Row>
    </>
  );

  function Filter() {
    return (
      <Collapse defaultActiveKey={1} onChange={() => {}}>
        {categories &&
          categories.map((cat) => (
            <Panel className="capitalize" header={cat.title} key={cat.id}>
              {cat.sub_category.map((e) => (
                <div className="checkboxes flex mb-3" key={e.id}>
                  <Checkbox
                    value={e.id}
                    checked={
                      checkedSubCategories[checkedSubCategories.length - 1]
                        ?.subCategory === e.id
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        setPage(1);
                        setCheckedSubCategories([
                          { category: cat.id, subCategory: e.target.value },
                        ]);
                      } else {
                        setPage(1);
                        console.log(checkedSubCategories);
                        let tempArr = checkedSubCategories;

                        let index = checkedSubCategories.findIndex((ob) => {
                          return (
                            ob.category === cat.id &&
                            ob.subCategory === e.target.value
                          );
                        });
                        tempArr.splice(index, 1);
                        console.log("temp arr => ", tempArr);
                        setCheckedSubCategories([...tempArr]);
                      }
                    }}
                  >
                    {e.title}
                  </Checkbox>
                </div>
              ))}
            </Panel>
          ))}
      </Collapse>
    );
  }
}

export default Products;
