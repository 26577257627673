import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  HomeOutlined,
  LeftOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

//
import { useSelector } from "react-redux";
import { selectCount } from "../store/cartSlice";
import CitySelection from "../components/CitySelection";
import logo from "../assets/images/logo.png";

const Header = () => {
  const count = useSelector(selectCount);

  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [openCitySelection, setOpenCitySelection] = useState(false);

  // Toggle mobile menu
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);

  // const User = JSON.parse(localStorage.getItem("user"));

  // useEffect(() => {
  //   setUserName(User?.first_name);
  // }, [User]);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="z-20 fixed top-0 left-0  w-screen  bg-white">
      {openCitySelection ? <CitySelection open /> : ""}
      <header>
        <div className=" z-10  bg-white fixed top-0 left-0" />
        <nav className=" border-b border-slate-200 px-4 lg:px-6 py-4">
          <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-2xl px-0 lg:px-6">
            {window.location.pathname === "/" ? (
              ""
            ) : (
              <div
                onClick={() => navigate(-1)}
                className={`inline-flex ${
                  window.location.pathname === "/" ? "invisible" : ""
                } items-center p-2 ml-1 text-2xl text-gray-500 rounded-lg hover:bg-gray-100 
 focus:outline-none focus:ring-2 focus:ring-gray-200`}
              >
                <LeftOutlined className="text-lg" />
              </div>
            )}
            <span
              className="cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <h1 className="font-extrabold text-2xl w-full text-left ml-3 mb-0 text-slate-800">
                <img src={logo} alt="" width={70}/>
              </h1>
            </span>

            <div className="flex items-center lg:order-2">
              {/* <button
                className="text-slate-900 transition-all focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-lg focus:outline-none 
                  items-center flex mr-2"
                onClick={() => {
                  setOpenCitySelection(true);
                }}
              >
                <img
                  src="https://img.icons8.com/material-sharp/48/000000/city-buildings.png"
                  width={25}
                  alt=""
                />
              </button> */}
              {!localStorage.getItem("token") && !userName ? (
                <button
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2  focus:outline-none 
                  items-center flex"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  Login <ArrowRightOutlined className="ml-3" />
                </button>
              ) : (
                <>
                  {/* <h1 className="mt-3">Welcome {userName + "    🎉  "} </h1> */}
                  <button
                    data-collapse-toggle="mobile-menu-2"
                    type="button"
                    className="inline-flex items-center p-2 ml-1 text-2xl text-gray-500 rounded-lg hover:bg-gray-100 
              focus:outline-none focus:ring-2 focus:ring-gray-200 "
                    aria-expanded="false"
                    onClick={() => {
                      navigate("/cart");
                    }}
                  >
                    <Badge count={count}>
                      <ShoppingCartOutlined className="text-2xl" />
                    </Badge>
                  </button>
                  <button
                    data-collapse-toggle="mobile-menu-2"
                    type="button"
                    className="hidden lg:inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg hover:bg-gray-100 
              focus:outline-none focus:ring-2 focus:ring-gray-200 
              "
                    aria-expanded="false"
                    onClick={handleLogout}
                  >
                    Logout
                    <ArrowRightOutlined className="ml-2" />
                  </button>
                  {/* <button
                    data-collapse-toggle="mobile-menu-2"
                    type="button"
                    className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                    aria-controls="mobile-menu-2"
                    aria-expanded="false"
                    onClick={() => {
                      setMobileMenuOpened(!mobileMenuOpened);
                    }}
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      className="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <svg
                      className="hidden w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button> */}
                </>
              )}
            </div>
            {/* <div
              className={`${
                mobileMenuOpened ? "" : "hidden"
              } justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              id="mobile-menu-2`}
            >
              <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0 mb-0">
                <li>
                  <span
                    className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 
                    cursor-pointer"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Home
                  </span>
                </li>
                <li>
                  <span
                    className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 
                    cursor-pointer"
                    onClick={() => {
                      navigate("/products");
                    }}
                  >
                    All Products
                  </span>
                </li>
                <li>
                  <span
                    href="#"
                    className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 
                    cursor-pointer"
                    onClick={() => {
                      navigate("/account");
                    }}
                  >
                    My Account
                  </span>
                </li>
                <li>
                  <span
                    href="#"
                    className="block lg:hidden py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0  "
                    onClick={handleLogout}
                  >
                    Logout
                  </span>
                </li>
              </ul>
            </div> */}
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
