// antd
import { Row, Col, Badge, Avatar, Button } from "antd";
import {
  HeartOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  StarFilled,
} from "@ant-design/icons";
import styled from "styled-components";
import { primary_color } from "../styles/styled/style_variables";
import AppButton from "./AppButton";
import { useNavigate } from "react-router-dom";
import Star from "./Star";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { createAlert } from "./Notification";
import ReactStars from "react-rating-stars-component";

function ProductMobile(props) {
  const navigate = useNavigate();

  let height = props.variant == "small" ? 220 : 400;

  return (
    <div className="product mb-5 mx-0.5">
      <div
        className="bg-white shadow-xs rounded overflow-hidden hover:shadow-lg  transition ease-in border-slate-100 border h-full flex flex-col"
        // style={{ minHeight: height + 30, maxHeight: height + 30 }}
        style={{ maxHeight: height + 30 }}
      >
        <div className="image" style={{ position: "relative", minHeight: 100 }}>
          <img
            className="w-full object-contain border-b border-solid border-slate-100 hover:cursor-pointer"
            style={{ height: 120 }}
            onClick={() => {
              navigate(`/products/view/?id=${props.id}`);
            }}
            src={props.image}
            alt="product_image"
          />
          <div className="absolute bottom-2 left-2">
            <Star count={props.star} />
          </div>
          <div className="absolute bottom-2 right-2">
            {props.assured ? (
              <div className="flex justify-center mt-3">
                <span className=" badge bg-sky-700 py-0.5 font-medium text-white px-2 text-xs rounded-full inline-flex items-center justify-center">
                  <img
                    src="https://img.icons8.com/external-inkubators-detailed-outline-inkubators/100/ffffff/external-verified-ecommerce-user-interface-inkubators-detailed-outline-inkubators.png"
                    width={16}
                    alt=""
                  />
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex flex-col justify-center   px-3">
          <div className="font-medium text-md text-center mt-2 leading-6 line-clamp-1">
            {props.title}
          </div>
          {/* {Boolean(props.stock_qty) &&
            (props?.stock_qty > 5 ? null : props.stock_qty <= 0 ? (
              // <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
              <span className="text-green-600 leading-6">Out of Stock!</span>
            ) : (
              <span className="text-red-800 leading-6">
                {" "}
                {`only ${props.stock_qty} left Hurry !`}
              </span>
            ))} */}

          <div className="flex justify-center mt-2">
            <div className="font-semibold whitespace-nowrap text-md text-left   flex  tracking-tight">
              <span className="mr-1"> ₹ </span>

              {props.price?.toLocaleString("en-IN")}
            </div>
            <div className="flex-col ">
              <div className="font-semibold whitespace-nowrap text-[12px] line-through text-left -mb-[1px] mt-1  ml-2 text-slate-400 tracking-tighter flex items-center">
                ₹ {props.mrp?.toLocaleString("en-IN")}
              </div>
            </div>
          </div>
          {/* <div className="font-semibold whitespace-nowrap text-[10px] text-left -mb-[10px] ml-2 text-green-700 tracking-tighter flex items-center">
            ( {(((props.mrp - props.price) / props.mrp) * 100).toFixed(0)}%
            &nbsp;off )
          </div> */}
        </div>
        <div className={`mb-5 pt-1 px-5 justify-between flex mt-auto `}>
          <button
            className="bg-white hover:bg-gray-100 text-gray-700 disabled:border-slate-200 disabled:text-slate-400 disabled:cursor-not-allowed  disabled:bg-gray-100 flex  font-semibold 
            px-3 rounded py-1.5 text-sm border whitespace-nowrap w-5/12 border-sky-200 leading-6 transition   items-center justify-center"
          >
            <ShareAltOutlined />
          </button>
          <button
            onClick={props.cartClick}
            disabled={props.stock_qty < -1}
            className="bg-sky-100 mr-2 disabled:border-slate-200 disabled:text-slate-400 disabled:cursor-not-allowed  disabled:bg-gray-100 flex hover:bg-sky-200 text-sky-700 font-semibold 
            px-3 rounded py-1.5 text-sm border whitespace-nowrap w-5/12 border-sky-200 leading-6 transition   items-center justify-center"
          >
            <ShoppingCartOutlined className="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProductMobile;
