// antd
import { Row, Col, Badge, Avatar, Button } from "antd";
import {
  HeartOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ShareAltOutlined,
  ShoppingCartOutlined,
  StarFilled,
} from "@ant-design/icons";
import styled from "styled-components";
import { primary_color } from "../styles/styled/style_variables";
import AppButton from "./AppButton";
import { useNavigate } from "react-router-dom";
import Star from "./Star";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { createAlert } from "./Notification";

const OfferTitle = styled.h1`
  color: ${primary_color};
  font-size: 16px;
  font-weight: 450;
  text-align: left;
  margin-bottom: 0;
`;

const Tagline = styled.h6`
  color: black;
  font-size: 14px;
  text-align: left;
  margin-bottom: 0;
`;

const OfferWrapper = styled.div`
  border: 1px solid #eee;
  border-radius: 2px;
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 18px -2px rgba(0, 0, 0, 0.1);
  }
`;

const ImageWrapper = styled.div`
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  overflow: hidden;
  border-radius: 1px;
  min-height: 180px;
  max-height: 180px;
  position: relative;
  img {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
    object-position: center;
  }
  .like {
    position: absolute;
    bottom: 10px;
    right: 10px;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const OfferDescription = styled.div`
  padding: 15px;
`;

const OfferPrice = styled.h1`
  font-size: 20px;
  /* margin-top: 10px; */
  /* letter-spacing: 1px; */
  /* letter-spacing: -1px; */
  font-weight: 500;
  margin-bottom: 0;
  small {
    margin-left: 6px;
    letter-spacing: -2px;
    font-size: 14px;
    text-decoration: line-through;
  }
`;

const OfferButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    width: 100%;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.primary {
      background-color: ${primary_color};
      border-color: ${primary_color};
    }
  }
`;

function Product(props) {
  const navigate = useNavigate();

  let height = props.variant == "small" ? 540 : 480;

  return (
    <div className="product mb-10 mx-4">
      <div
        className="bg-white shadow-sm rounded overflow-hidden hover:shadow-lg  transition ease-in border-slate-100 border h-full flex flex-col"
        //style={{ minHeight: height + 30, maxHeight: height + 30 }}
      >
        <div className="image" style={{ position: "relative" }}>
          <img
            className="w-full object-contain border-b border-solid border-slate-100 hover:cursor-pointer"
            style={{ height: 180 }}
            onClick={() => {
              navigate(`/products/view/?id=${props.id}`);
            }}
            src={props.image}
            alt="product_image"
          />
          {props.star > 0 ? (
            <div className="absolute bottom-5 left-5">
              <Star count={props.star} />
            </div>
          ) : (
            ""
          )}
          {props.assured ? (
            <span className="absolute top-3 right-3 badge bg-sky-700 py-1 font-medium text-white px-2 text-xs rounded-full inline-flex items-center justify-center">
              <img
                src="https://img.icons8.com/external-inkubators-detailed-outline-inkubators/100/ffffff/external-verified-ecommerce-user-interface-inkubators-detailed-outline-inkubators.png"
                width={16}
                className="mr-1"
                alt=""
              />
              Assured
            </span>
          ) : (
            ""
          )}
        </div>

        <div className="flex flex-col py-4 px-6">
          <div className="font-medium text-lg text-left line-clamp-1">
            {props.title}
          </div>
          {Boolean(props.stock_qty) &&
            (props?.stock_qty > 5 ? null : props.stock_qty <= 0 ? (
              // <span class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
              <p className="text-green-600">Out of Stock!</p>
            ) : (
              <p className="text-red-800">
                {" "}
                {`only ${props.stock_qty} left Hurry !`}
              </p>
            ))}

          <p
            className="text-slate-400 mt-1 text-sm line-clamp-2"
            style={{ minHeight: 40 }}
          >
            {props.description}
          </p>
          <div className="flex align-center  mt-3">
            <div className="font-semibold whitespace-nowrap text-xl text-left  tracking-tight">
              ₹ {props.price?.toLocaleString("en-IN")}
            </div>
            <div className="font-semibold whitespace-nowrap text-sm line-through text-left  ml-2 text-slate-400 tracking-tighter flex items-center">
              ₹ {props.mrp?.toLocaleString("en-IN")}
            </div>
            <div className="font-semibold whitespace-nowrap text-sm text-left  ml-3 text-green-700 tracking-tighter flex items-center">
              {(((props.mrp - props.price) / props.mrp) * 100).toFixed(0)} %
              &nbsp;Discount
            </div>
          </div>
        </div>
        <div
          className={`mb-5 pt-2 px-4 flex mt-auto ${
            props.variant === "small" ? "flex-wrap" : ""
          }`}
        >
          <button
            onClick={() => {
              let url = window.location.host;
              navigator.clipboard.writeText(
                `${url}/products/view/?id=${props.id}`
              );
              createAlert("success", "Copied url to clipboard.");
            }}
            className={`bg-white hover:bg-gray-100 text-gray-700 font-semibold 
            px-4 rounded text-sm border whitespace-nowrap border-slate-200 leading-10 transition w-full flex justify-center ${
              props.variant === "small" ? "mb-2" : "mr-2"
            }`}
          >
            <ShareAltOutlined className="mt-3 mr-2" /> Share
          </button>
          <button
            onClick={props.cartClick}
            disabled={props.stock_qty < -1}
            className="bg-sky-100 disabled:border-slate-200 disabled:text-slate-400 disabled:cursor-not-allowed  disabled:bg-gray-100 flex hover:bg-sky-200 text-sky-700 font-semibold 
            px-4 rounded text-sm border whitespace-nowrap border-sky-200 leading-10 transition w-full  items-center justify-center"
          >
            <ShoppingCartOutlined className="mr-2" /> Add to cart
          </button>
          {/* <button
            onClick={props.cartClick}
            className="bg-sky-100 block sm:hidden hover:bg-sky-200 text-sky-700 font-semibold 
            px-4 rounded text-sm border border-sky-200 leading-10 transition w-full  items-center justify-center"
          > */}
          {/* <ShoppingCartOutlined
              className="mr-2 "
              style={{ fontSize: "25px" }}
            />
            <PlusCircleOutlined /> */}
          {/* <FontAwesomeIcon
              size="lg"
              className="text-blue-800"
              icon={faCartPlus}
            />
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Product;
