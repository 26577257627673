import {
  FacebookFilled,
  FacebookOutlined,
  HomeOutlined,
  InstagramOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";

function Footer() {
  return (
    <>
      <div id="app-footer" className="mt-10  bg-slate-900 px-4 lg:px-6">
        <div className="container mx-auto px-4 lg:px-6 pt-4">
          <Row gutter={24}>
            <Col span={24} lg={6}>
              <h1 className="text-2xl text-white">
                <img src={logo} alt="" />
              </h1>
            </Col>
            <Col span={24} lg={6}>
              <h1 className="text-md text-slate-300 mb-2">Contact info</h1>
              <hr className="border-slate-700" />
              <div className="text-left mt-3 text-slate-300 mb-5">
                <span className="font-medium text-slate-400 mb-1 d-block">
                  Addresss
                </span>
                <p>
                  D. No:124-G-8, Naganampatti, <br />
                  Opp Sai baba kovil, Oddanchatram, <br />
                  Dindigul, Tamilnadu - 624619
                </p>
              </div>
              <div className="text-left mt-3 text-slate-300 mb-5">
                <span className="font-medium text-slate-400 mb-1 d-block">
                  Email Address
                </span>
                <p>igetin@outlook.com</p>
              </div>
              <div className="text-left mt-3 text-slate-300 mb-5">
                <span className="font-medium text-slate-400 mb-1 d-block">
                  Phone number
                </span>
                <p> +91 88701 17119 </p>
              </div>
            </Col>
            <Col span={24} lg={6}>
              <h1 className="text-md text-slate-300 mb-2"> Quick Links </h1>
              <hr className="border-slate-700" />
              <div className="text-left mt-3 mb-5">
                <Link
                  to="/"
                  className="block mb-2 text-slate-400 transition hover:text-slate-200"
                >
                  Home
                </Link>
                <Link
                  to="/account"
                  className="block mb-2 text-slate-400 transition hover:text-slate-200"
                >
                  My Account
                </Link>
                <Link
                  to="/privacy"
                  className="block mb-2 text-slate-400 transition hover:text-slate-200"
                >
                  Privacy policy
                </Link>
                <Link
                  to="/refund"
                  className="block mb-2 text-slate-400 transition hover:text-slate-200"
                >
                  Refund & Cancelation policy
                </Link>
                <Link
                  to="/terms"
                  className="block mb-2 text-slate-400 transition hover:text-slate-200"
                >
                  Terms and conditions
                </Link>
              </div>
            </Col>
            <Col span={24} lg={6}>
              <h1 className="text-md text-slate-300 mb-2">Follow us</h1>
              <hr className="border-slate-700" />
              <div className="icons text-slate-300 mt-3 mb-3 text-3xl text-left">
                <FacebookFilled className="mr-8" />
                <TwitterOutlined className="mr-8" />
                <InstagramOutlined className="mr-8" />
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div className="footer-bottom  sm:py-5 bg-slate-800 p-5 text-slate-300">
        <div className="container mx-auto px-4 lg:px-6">
          <p className="mb-0"> © IGETIN 2022, All rights reserved. </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
