import httpClient from "./config";

//login request
export const api_login = async (payload) => {
  return await httpClient
    .post(`/login/`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const api_register = async (user) => {
  return await httpClient
    .post(`/user/`, user)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const api_verify_password = async (payload) => {
  return await httpClient
    .put(`/user/?verification=true`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const api_forgot_password = async (payload) => {
  return await httpClient
    .put(`/user/?forgot=true`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const logout = () => {
  localStorage.removeItem("token");
};
