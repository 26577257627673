import React from "react";
import { Form, Input, Row, Col, Radio, DatePicker } from "antd";
import { api_register } from "../../services/auth.service";
import { createAlert } from "../../components/Notification";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";

function Register() {
  let navigate = useNavigate();
  const handleFormSuccess = (values) => {
    console.log(values);

    values["date_of_birth"] = moment(values["date_of_birth"]).format(
      "YYYY-MM-DD"
    );

    api_register({
      ...values,
      username: values.mobile,
      role: "customer",
      device_token: localStorage.getItem("device_token"),
    }).then((res) => {
      console.log(res);
      if (res.data.success) {
        console.log(res.data.success);
        createAlert("success", res.data.message.toLowerCase());
        navigate("/login");
      } else {
        createAlert("error", res.message);
      }
    });
  };

  const handleFormError = (params) => {
    // alert(1);
  };

  return (
    <>
      <section className="sm:mt-20 sm:mb-0">
        <div className="px-6 h-full text-gray-800">
          <div className="container h-full">
            <div className="flex xl:justify-center lg:justify-between justify-center items-center flex-wrap h-full g-6">
              <div className="grow-0 hidden sm:block shrink-1 md:shrink-0 basis-auto xl:w-6/12 lg:w-6/12 md:w-9/12 mb-12 md:mb-0">
                <img
                  src="https://img.freepik.com/free-vector/mobile-login-concept-illustration_114360-83.jpg?w=2000"
                  className="w-full"
                  alt="Sample"
                />
              </div>
              <div className="xl:ml-20 xl:w-5/12 lg:w-5/12 md:w-8/12 mb-12 md:mb-0">
                <div className="flex flex-row items-center justify-center lg:justify-start mt-10">
                  <h1 className="text-4xl font-extrabold sen">Join IGETIN</h1>
                </div>

                <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                  <p className="text-center font-semibold mx-4 mb-0">Welcome</p>
                </div>

                <Form
                  name="basic"
                  layout="vertical"
                  initialValues={{ remember: true }}
                  onFinish={handleFormSuccess}
                  onFinishFailed={handleFormError}
                  autoComplete="off"
                >
                  <Form.Item
                    className="hide-astricks"
                    label="Mobile number"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Please enter mobile number.",
                      },
                      {
                        min: 10,
                        message:
                          "Mobile number should contain atleast 10 characters.",
                      },
                      {
                        max: 10,
                        message: "Mobile number cannot exceed 10 characters.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Mobile Number" type="number" />
                  </Form.Item>
                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item
                        className="hide-astricks"
                        label="Name"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Name.",
                          },
                        ]}
                      >
                        <Input type="text" placeholder="Enter Name" />
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <Form.Item
                        className="hide-astricks"
                        label="Last name"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter last name.",
                          },
                        ]}
                      >
                        <Input type="text" placeholder="Enter last name" />
                      </Form.Item>
                    </Col> */}
                  </Row>
                  <Row gutter={12}>
                    <Col span={24}>
                      <Form.Item
                        className="hide-astricks"
                        label="Email address"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter email address.",
                          },
                        ]}
                      >
                        <Input type="email" placeholder="Enter email address" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        className="hide-astricks"
                        label="Pincode"
                        name="pincode"
                        rules={[
                          {
                            required: true,
                            message: "Please enter pincode",
                          },
                        ]}
                      >
                        <Input type="number" placeholder="Enter pincode" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    className="hide-astricks"
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter password.",
                      },
                    ]}
                  >
                    <Input type="password" placeholder="Enter Password" />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    className="hide-astricks"
                    name="confirmPassword"
                    type="password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm your password" />
                  </Form.Item>
                  {/* <Form.Item
                    label="Gender"
                    className="hide-astricks"
                    name="gender"
                  >
                    <Radio.Group>
                      <Radio.Button value="male"> Male</Radio.Button>
                      <Radio.Button value="female">Female</Radio.Button>
                    </Radio.Group>
                  </Form.Item> */}
                  <Form.Item className="mb-0">
                    <div className="text-center lg:text-left">
                      <button
                        type="submit"
                        className="w-full px-7 py-3 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out mb-0"
                      >
                        Sign up
                      </button>
                    </div>
                  </Form.Item>
                </Form>

                <div className="flex justify-between items-center mb-6">
                  <p className="text-sm font-semibold mt-2 pt-1 mb-0">
                    Already have an account ?
                    <Link
                      to="/login"
                      className="text-blue-600 hover:text-blue-700 focus:text-blue-700 transition duration-200 ease-in-out ml-1"
                    >
                      Login
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Register;
